import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';

import logoImg from 'static/assets/img/LOGO24.png';

import { ERoutes } from 'static/data/ERoutes';
import { getULogo } from 'static/assets/icons';

export const SplashPage: React.FC = () => {
  const router = useIonRouter();
  const [opacityClass, setOpacityClass] = useState('opacity-0');

  useEffect(() => {
    console.log('Splash page mounted.');
    setOpacityClass('opacity-100');
    const timeoutFef = setTimeout(() => {
      router.push(ERoutes.PHONE_ACCESS, 'forward');
    }, 2000);

    return () => {
      console.log('Splash page unmounted.');
      clearTimeout(timeoutFef);
    };
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div className="flex flex-col justify-center items-center h-full">
          <div className={`flex flex-col items-end gap-2 ${opacityClass} transition-opacity duration-1000`}>
            <img src={logoImg} height={200} alt="crowdsurfr" />
            <div className="flex flex-row items-end">
              <span>Because</span> {getULogo()} <span>decide</span>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
