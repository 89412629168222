export enum ERoutes {
  BASE = '/',
  LANDING = '/landing',
  PHONE_ACCESS = '/phone-access',
  WELCOME = '/welcome',
  HOME = '/home',
  MY_EVENTS = '/my-events',
  SHOW_TICKET = '/show-ticket',
  PROFILE = '/profile',
  EVENT_DETAILS = '/event-details',
  TICKET_CLAIMED = '/ticket-claimed',

  TRUST_AND_SAFETY = '/trust-and-safety',
  TERMS_OF_USE = '/terms-of-use',
  PRIVACY_POLICY = '/privacy-policy',
}
