import React from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import { useParams } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import { Header } from 'ui/components/Header';
import { appEvents, unclaimTicket } from 'apis/general';
import { computed, signal } from '@preact/signals-react';
import { useAuthUserStore } from 'store/user-store';
import { getCalendarIcon, getMapPinIcon } from 'static/assets/icons';
import { mainDateFormat } from 'ui/helpers';
import ticketBg from 'static/assets/img/ticket-bg.png';

const isCancellingTicket = signal<boolean>(false);
export const ShowTicketPage: React.FC = () => {
  const router = useIonRouter();
  const { event_id } = useParams<{ event_id: string }>();
  const { authUser } = useAuthUserStore();

  const appEvent = computed(() => appEvents.value.find((ev) => ev.id === event_id));

  const handleCancelTicket = async () => {
    isCancellingTicket.value = true;
    await unclaimTicket(event_id, authUser?.id ?? '');
    isCancellingTicket.value = false;
    router.goBack();
  };

  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <div className="content-section flex flex-col justify-start items-center h-full">
          <Header title="Tickets" />
          <h2 className="mt-10 animate-bounce">Scan this qr at event</h2>

          {/* Ticket Container with Padding Hack for 1:1.8 AR safari */}
          <div className="relative mt-2 w-full max-w-[344px] mx-auto h-[650px] max-h-[650px] min-h-[650px] bg-no-repeat" style={{ backgroundImage: `url('${ticketBg}')` }}>
            {/* QR Code Container */}
            <div className="absolute inset-0 h-[75vw] max-h-[300px] flex justify-center items-center">
              <div className="w-[70%] h-[70%] flex flex-col justify-center items-center animate-pulse z-10">
                <QRCodeSVG
                  className="w-full"
                  width="60vw"
                  height="60vw"
                  fgColor="#FA02FF"
                  bgColor="transparent"
                  value={`https://verify.crowdsurfr.com?event_id=${event_id}&profile_id=${authUser?.id}`}
                  level="L"
                />
              </div>
            </div>

            {/* Content Below QR Code */}
            <div className="absolute top-[48%] w-full py-2 px-4 mt-2 text-center z-10">
              <h2>{appEvent.value?.title}</h2>

              <div className="flex flex-row justify-between w-full">
                <div className="text-xs flex flex-row items-center gap-0.5 flex-1">
                  <span className="w-[20px]">{getCalendarIcon()}</span> <span className="truncate">{appEvent.value?.date && mainDateFormat(new Date(appEvent.value.date))}</span>
                </div>
                <div className="text-xs flex flex-row items-center justify-end gap-0.5 flex-1">
                  <span className="w-[20px]">{getMapPinIcon()}</span> <span className="truncate">{appEvent.value?.location}</span>
                </div>
              </div>
              <p className="text-sm overflow-hidden text-ellipsis line-clamp-3">{appEvent.value?.description ?? '...'}</p>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <button className="truncate standard-btn outlined" disabled={true}>
                  Pre-party
                </button>
                <button className="truncate standard-btn outlined" disabled={true}>
                  After party
                </button>
                <button className="truncate standard-btn-secondary" disabled={isCancellingTicket.value} onClick={handleCancelTicket}>
                  Cancel
                </button>
                <button className="truncate standard-btn" disabled={true}>
                  Gift ticket
                </button>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
