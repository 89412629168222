import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Header } from 'ui/components/Header';

export const TermsOfUsePage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <div className="content-section flex flex-col justify-start items-center h-full">
          <Header title="Terms of Use" />

          <section className="p-2 mt-4">
            <p className="font-bold text-white text-lg my-4">1. Welcome to CROWDSURFR</p>
            <p>
              By using this website (the “Site”) and services (together with the Site, the “Services”) offered by Crowdsurfr ApS (together with its parents, subsidiaries,
              affiliates, agents, representatives, consultants, employees, officers, and directors — collectively, “CROWDSURFR,” “we,” or “us”), you agree to these legally binding
              rules (the “Terms”). You also agree to our privacy policy and any other rules on the Site. We may change these Terms from time to time. If we do, we’ll notify you of
              any material changes, either by notifying you on the Site or by sending you an email. New versions of the Terms will never apply retroactively — we’ll tell you the
              exact date they go into effect. If you continue to use CROWDSURFR after a change, that means you accept the new Terms. CROWDSURFR is for your personal, non-commercial
              use.
            </p>
            <p className="font-bold text-white text-lg my-4">2. About Creating an Account</p>
            <p>
              To use some of CROWDSURFR’s functions, you’ll need to register, choose a username, and set a password. The information you provide must be accurate and complete.
              Don’t impersonate anyone else or choose names that are offensive or violate anyone’s rights. If you don’t follow these rules, we may cancel your account. You’re
              responsible for all activity on your account and for keeping your password confidential. If you find out that someone has used your account without your permission,
              report it to privacy@crowdsurfr.com. To sign up for an account, you need to be at least 18 years old, or old enough to form a binding contract where you live. We may
              ask for proof of age if necessary.
            </p>
            <p className="font-bold text-white text-lg my-4">3. Things You Definitely Shouldn’t Do</p>
            <p>We expect all users of CROWDSURFR to behave responsibly and help keep this a pleasant place. Don’t do any of the following on the Site:</p>
            <p>
              - Don’t break the law. Don’t take any action that infringes or violates other people’s rights, violates the law, or breaches any contract or legal duty you have
              toward anyone.
            </p>
            <p> - Don’t lie to people. Don’t post information you know is false, misleading, or inaccurate. Don’t do anything deceptive or fraudulent.</p>{' '}
            <p>
              - Don’t victimize anyone. Don’t do anything threatening, abusive, harassing, defamatory, libelous, tortious, obscene, profane, or invasive of another person’s
              privacy.
            </p>
            <p>
              - Don’t spam. Don’t distribute unsolicited or unauthorized advertising or promotional material, or any junk mail, spam, or chain letters. Don’t run mail lists,
              listservs, or any kind of auto-responder or spam on or through the Site.
            </p>
            <p>
              - Don’t harm anyone’s computer. Don’t distribute software viruses, or anything else (code, films, programs) designed to interfere with the proper function of any
              software, hardware, or equipment on the Site (whether it belongs to CROWDSURFR or another party).
            </p>
            <p>
              - Don’t abuse other users’ personal information. When you use CROWDSURFR, you may receive information about other users, including their names, email addresses, and
              postal addresses. This information is provided for the purpose of participating in the CROWDSURFR network; don’t use it for other purposes, and don’t abuse it.
            </p>
            <p>- Don’t interfere with the proper workings of the Services. </p>
            <p>- Don’t bypass any measures we’ve put in place to secure the Services. </p>
            <p>- Don’t try to damage or get unauthorized access to any system, data, password, or other information, whether it belongs to CROWDSURFR or another party.</p>
            <p>
              - Don’t take any action that imposes an unreasonable load on our infrastructure, or on our third-party providers. (We reserve the right to determine what’s
              reasonable.)
            </p>
            <p>- Don’t use any kind of software or device (whether it’s manual or automated) to “crawl” or “spider” any part of the Site. </p>
            <p>- Don’t take apart or reverse engineer any aspect of CROWDSURFR in an effort to access things like source code, underlying ideas, or algorithms.</p>
            <p className="font-bold text-white text-lg my-4">4. Stuff We Don’t Do and Aren’t Responsible For</p>
            <p>
              CROWDSURFR isn’t liable for any damages or losses related to your use of the Services. We don’t become involved in disputes between users, or between users and any
              third party relating to the use of the Services. We don’t oversee the performance or punctuality of shows, and we don’t endorse any content users submit to the Site.
              When you use the Services, you release CROWDSURFR from claims, damages, and demands of every kind — known or unknown, suspected or unsuspected, disclosed or
              undisclosed — arising out of or in any way related to such disputes and the Services. All content you access through the Services is at your own risk. You’re solely
              responsible for any resulting damage or loss to any party.
            </p>
            <p className="font-bold text-white text-lg my-4">5. Other Websites</p>
            <p>
              CROWDSURFR may contain links to other websites (e.g., band pages, user profiles, and comments). When you access third-party websites, you do so at your own risk. We
              don’t control or endorse those sites.
            </p>
            <p className="font-bold text-white text-lg my-4">6. Your Intellectual Property</p>
            <p>
              CROWDSURFR doesn’t own content you submit to us (your “Content”). However, we need certain licenses from you to perform our Services. If you submit a project for
              review or launch a project, you agree to these terms:
            </p>
            <p>
              - We can use the content you’ve submitted. You grant us, and others acting on our behalf, the worldwide, non-exclusive, perpetual, irrevocable, royalty-free,
              sublicensable, transferable right to use, exercise, commercialize, and exploit the copyright, publicity, trademark, and database rights with respect to your Content.
            </p>
            <p>
              - When we use the content, we can make changes, like editing or translating it. You grant us the right to edit, modify, reformat, excerpt, delete, or translate any of
              your Content.
            </p>
            <p>
              - You won’t submit stuff you don’t hold the copyright for (unless you have permission). Your Content will not contain third-party copyrighted material, or material
              that is subject to other third-party proprietary rights, unless you have permission from the rightful owner of the material, or you are otherwise legally entitled to
              post the material (and to grant CROWDSURFR all the license rights outlined here).
            </p>
            <p>
              - Any royalties or licensing on your Content are your responsibility. You will pay all royalties and other amounts owed to any person or entity based on your Content,
              or on CROWDSURFR’s hosting of that Content.
            </p>
            <p>
              - You promise that if we use your Content, we’re not violating anyone’s rights or copyrights. If CROWDSURFR or its users exploit or make use of your submission in the
              ways contemplated in this agreement, you promise that this will not infringe or violate the rights of any third party, including (without limitation) any privacy
              rights, publicity rights, copyrights, contract rights, or any other intellectual property or proprietary rights.{' '}
            </p>
            <p>
              - You’re responsible for the stuff you post. All information submitted to the Site, whether publicly posted or privately transmitted, is the sole responsibility of
              the person from whom that content originated.
            </p>
            <p>- We’re not responsible for mistakes in your content. CROWDSURFR will not be liable for any errors or omissions in any content.</p>
            <p className="font-bold text-white text-lg my-4">7. CROWDSURFR’s Intellectual Property</p>
            <p>
              CROWDSURFR’s Services are legally protected in various ways, including copyrights, trademarks, service marks, patents, trade secrets, and other rights and laws. You
              agree to respect all copyright and other legal notices, information, and restrictions contained in any content accessed through the Site. You also agree not to
              change, translate, or otherwise create derivative works of the Service. CROWDSURFR grants you a license to reproduce content from the Services for personal use only.
              This license covers both CROWDSURFR’s own protected content and user-generated content on the Site. (This license is worldwide, non-exclusive, non-sublicensable, and
              non-transferable.) If you want to use, reproduce, modify, distribute, or store any of this content for a commercial purpose, you need prior written permission from
              CROWDSURFR or the relevant copyright holder. A “commercial purpose” means you intend to use, sell, license, rent, or otherwise exploit content for commercial use, in
              any way.
            </p>
            <p className="font-bold text-white text-lg my-4">8. Deleting Your Account</p>
            <p>
              You can terminate your account at any time through your account settings. We may retain certain information as required by law or as necessary for our legitimate
              business purposes. All provisions of this agreement survive termination of an account, including our rights regarding any content you’ve already submitted to the
              Site. You can contact us at dataprotection@crowdsurfr.com for additional information or to request project page deletion (this is not available in all circumstances).
            </p>
            <p className="font-bold text-white text-lg my-4">9. Our Rights</p>
            <p>
              CROWDSURFR reserves these rights: - We can make changes to the CROWDSURFR Site and Services without notice or liability. - We have the right to decide who’s eligible
              to use CROWDSURFR. We can cancel accounts or decline to offer our Services. (Especially if you’re abusing them.) We can change our eligibility criteria at any time.
              If these things are prohibited by law where you live, then we revoke your right to use CROWDSURFR in that jurisdiction. - We have the right to reject, cancel,
              interrupt, remove, or suspend any project at any time and for any reason. CROWDSURFR is not liable for any damages as a result of any of these actions, and it is our
              policy not to comment on the reasons for any such action.
            </p>
            <p className="font-bold text-white text-lg my-4">10. Warranty Disclaimer</p>
            <p>
              We work hard to provide you with great Services, but we can’t guarantee everything will always work perfectly. To the fullest extent permitted by law, CROWDSURFR
              makes no warranties, either express or implied, about the Services. The Services are provided “as is” and “as available.” We also disclaim any warranties of
              merchantability, fitness for a particular purpose, and non-infringement. Some jurisdictions do not allow the disclaimers in this paragraph, so they may not apply to
              you.
            </p>
            <p className="font-bold text-white text-lg my-4">11. Limitation of Liability</p>
            <p>
              To the fullest extent permitted by law, in no event will CROWDSURFR, its directors, employees, partners, suppliers, or content providers be liable for any indirect,
              incidental, punitive, consequential, special, or exemplary damages of any kind, including but not limited to damages (i) resulting from your access to, use of, or
              inability to access or use the Services; (ii) for any lost profits, data loss, or cost of procurement of substitute goods or services; or (iii) for any conduct of
              content of any third party on the Site. In no event shall CROWDSURFR’s liability for direct damages be in excess of (in the aggregate) one hundred Danish Krone (DKK
              100.00).
            </p>
            <p className="font-bold text-white text-lg my-4">12. Dispute Resolution and Governing Law</p>
            <p>
              We at CROWDSURFR encourage you to contact us if you’re having an issue before resorting to the courts. In the unfortunate situation where legal action does arise,
              these Terms (and all other rules, policies, or guidelines incorporated by reference) will be governed by and construed in accordance with the laws of Denmark and the
              European Union, without giving effect to any principles of conflicts of law, and without application of the United Nations Convention on Contracts for the
              International Sale of Goods. You agree that CROWDSURFR and its Services are deemed a passive website that does not give rise to jurisdiction over CROWDSURFR or its
              parents, subsidiaries, affiliates, assigns, employees, agents, directors, officers, or shareholders, either specific or general, in any jurisdiction other than
              Denmark. You agree that any action at law or in equity arising out of or relating to these Terms or your use of the Services shall be filed only in the Danish courts
              located in Copenhagen, and you hereby consent and submit to the personal jurisdiction of such courts for the purposes of litigating any such action. The prevailing
              party in any action or proceeding to enforce this agreement shall be entitled to costs and attorneys’ fees. If any provision of these Terms is held to be invalid or
              unenforceable, the remaining provisions of these Terms will remain in full force and effect. These Terms constitute the entire agreement between you and CROWDSURFR
              with respect to the Services, and supersede and replace any other agreements, terms, or conditions applicable to the Services. These Terms create no third party
              beneficiary rights.
            </p>
            <p className="font-bold text-white text-lg my-4">13. Miscellaneous</p>
            <p>
              These Terms and the other material referenced in them are the entire agreement between you and CROWDSURFR with respect to the Services. They supersede all other
              communications and proposals (whether oral, written, or electronic) between you and CROWDSURFR with respect to the Services and govern our future relationship. If any
              provision of these Terms is found to be invalid under the law, that provision will be limited or eliminated to the minimum extent necessary, so that the Terms
              otherwise will remain in full force and effect and enforceable. The failure of either you or CROWDSURFR to exercise any right provided for in these Terms in any way
              won’t be deemed a waiver of any other rights.
            </p>
            <p>
              You can’t assign, transfer, or sublicense these Terms without CROWDSURFR’s prior written consent. CROWDSURFR may assign, transfer, or delegate any of its rights and
              obligations under these Terms without your consent. Both you and CROWDSURFR agree that these Terms and all related documents are in English. If you have any
              questions, please send us an email at info@crowdsurfr.com.
            </p>
            <p className="font-bold text-white text-lg my-4">14. Contact Information</p>
            <p className="font-bold text-white my-4">
              Crowdsurfr ApS
              <br />
              Denmark
              <br />
              Email: yo@crowdsurfr.com
            </p>
          </section>
        </div>
      </IonContent>
    </IonPage>
  );
};
