import React, { useEffect } from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import { Header } from 'ui/components/Header';
import { useAuthUserStore } from 'store/user-store';
import { reFetchProfileRewards, reFetchUserEvents, reFetchUserWaitlists } from 'apis/general';
import ticketImg from 'static/assets/img/ticket.png';
import fireworksImg from 'static/assets/img/fireworks.png';
import { ERoutes } from 'static/data/ERoutes';
import { useParams } from 'react-router-dom';
import { useComputed } from '@preact/signals-react';

export const TicketClaimedPage: React.FC = () => {
  const { is_waitlist } = useParams<{ is_waitlist: string }>();
  const isWaitlist = useComputed(() => is_waitlist === 'true');
  const router = useIonRouter();
  const { authUser } = useAuthUserStore();

  useEffect(() => {
    console.log('TicketClaimedPage mounted.');

    if (authUser?.id) {
      if (isWaitlist.value) void reFetchUserWaitlists(authUser.id);
      else void reFetchUserEvents(authUser.id);
      void reFetchProfileRewards(authUser.id);
    }
  }, []);
  console.log(isWaitlist.value);

  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <div className="content-section flex flex-col justify-start items-center h-full">
          <Header title=" " onBack={() => router.push(ERoutes.HOME, 'root', undefined, { unmount: true })} />
          <div className="h-full flex flex-col items-center justify-center">
            <div className="w-full h-auto relative flex flex-col justify-center items-center mt-[-20%] mb-16 pointer-events-none">
              <img src={fireworksImg} alt="fireworks" className="w-full absolute animate-ping" />
              <img src={ticketImg} alt="ticket claimed!" className="w-auto animate-pulse" />
            </div>
            <h1 className="text-center leading-8">{isWaitlist.value ? 'Thank you for the interest' : 'You’re in!'}</h1>
            <br />
            <h1 className="text-center leading-8">{isWaitlist.value ? 'We will notify you if tickets become available' : 'You secured a ticket'}</h1>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
