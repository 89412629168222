import React, { useEffect } from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import { Header } from 'ui/components/Header';
import { FeedActivityCard } from 'ui/components/cards/FeedActivityCard';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { reFetchUserEvents, userEvents } from 'apis/general';
import { useAuthUserStore } from 'store/user-store';
import { ERoutes } from 'static/data/ERoutes';

type FixedRow = {
  index: number;
  style: any;
};

export const MyEventsPage: React.FC = () => {
  const router = useIonRouter();
  const { authUser } = useAuthUserStore();
  console.log('MyEventsPage rendered.');

  useEffect(() => {
    if (authUser) reFetchUserEvents(authUser.id).then((data) => (userEvents.value = data));
  }, []);

  const Row = ({ index, style }: FixedRow) => {
    const item = userEvents.value[index];
    return (
      <div
        style={{
          ...style,
          paddingBottom: '25px',
        }}
      >
        <FeedActivityCard
          appEvent={item}
          onClick={() => {
            const route = `${ERoutes.SHOW_TICKET}/${item.id}`;
            console.log(route);
            router.push(route, 'forward');
          }}
        />
      </div>
    );
  };

  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <div className="content-section flex flex-col justify-start items-center h-full">
          <Header title="My Events" />

          <div className="w-full h-[400px] relative mt-10 flex-1">
            <div className="absolute left-0 h-full w-full">
              {userEvents.value.length === 0 && <h2 className="text-center">No events found.</h2>}
              <AutoSizer>
                {({ height, width }) => (
                  <List className="List hidden-scrollbar" height={height} width={width} itemSize={190} itemCount={userEvents.value.length}>
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
