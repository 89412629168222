import React, { useEffect } from 'react';
import { IonContent, IonPage, useIonAlert, useIonRouter } from '@ionic/react';
import { useParams } from 'react-router-dom';
import { Header } from 'ui/components/Header';
import { appEvents, appEventView, claimTicket, joinWaitlist, reFetchEventView, reFetchUserEvents, userEvents, userWaitlists } from 'apis/general';
import { computed, signal } from '@preact/signals-react';
import { getCalendarIcon, getMapPinIcon } from 'static/assets/icons';
import { mainDateFormat } from 'ui/helpers';
import { LoadingSpinner } from 'ui/components/animations/LoadingSpinner';
import { differenceInDays, differenceInSeconds, intervalToDuration } from 'date-fns';
import { useAuthUserStore } from 'store/user-store';
import { ERoutes } from 'static/data/ERoutes';
import logo from 'static/assets/img/LOGO24.png';

const interval = signal<NodeJS.Timeout>();
const secondsSinceLastUpdate = signal<number>(0);
const isUpdating = signal<boolean>(false);
const countdown = signal<Duration>();

const isClaiming = signal<boolean>(false);

export const EventDetailsPage: React.FC = () => {
  const { event_id } = useParams<{ event_id: string }>();
  const router = useIonRouter();

  const { authUser } = useAuthUserStore();
  const [presentAlert] = useIonAlert();

  const appEvent = computed(() => appEvents.value.find((ev) => ev.id === event_id));
  const eventTickets = computed(() => appEventView.value?.find((view) => view.event_id === event_id));
  const pctBooked = computed(() => 100 - ((eventTickets.value?.claimed_tickets ?? 0) / (eventTickets.value?.total_tickets ?? 1)) * 100);
  const hasTicket = computed(() => userEvents.value.some((ev) => appEvent.value?.id === ev.id));
  const isOnWaitlist = computed(() => userWaitlists.value.some((wl) => wl.event_id === event_id));
  const sponsorImage = computed(() => (appEvent.value?.sponsor_image_url && appEvent.value.sponsor_image_url.length > 0 ? appEvent.value.sponsor_image_url : logo));

  useEffect(() => {
    void getTicketCount();
    void reFetchUserEvents(authUser?.id ?? '');
    updateCountdown();

    interval.value = setInterval(() => {
      updateCountdown();

      if (!isUpdating.value && secondsSinceLastUpdate.value > 3) void getTicketCount();
      else secondsSinceLastUpdate.value += 1;
    }, 1000);

    return () => {
      clearInterval(interval.value);
    };
  }, []);

  const getTicketCount = async () => {
    isUpdating.value = true;

    reFetchEventView().finally(() => {
      secondsSinceLastUpdate.value = 0;
      isUpdating.value = false;
    });
  };

  const updateCountdown = () => {
    if (appEvent.value?.date) {
      const eventDate = new Date(appEvent.value.date);
      const now = new Date();
      const diffInSeconds = differenceInSeconds(eventDate, now);

      if (diffInSeconds > 0) {
        countdown.value = intervalToDuration({ start: now, end: eventDate });
        countdown.value.days = differenceInDays(eventDate, now); // Calculate total days, to include months/years.
      }
    }
  };

  const handleClaimTicket = async () => {
    if (!authUser) return;
    if (eventTickets.value?.remaining_tickets === null || eventTickets.value?.remaining_tickets === undefined) return;
    isClaiming.value = true;

    let success = false;
    let isWaitlist = false;
    if (eventTickets.value.remaining_tickets > 0) success = await claimTicket(event_id, authUser.id);
    else {
      success = await joinWaitlist(event_id, authUser.id);
      isWaitlist = true;
    }
    isClaiming.value = false;

    if (!success) {
      await presentAlert({
        header: 'Failed to claim ticket',
        message: 'It seems we couldn’t claim your ticket. We may be all out.',
        buttons: ['OK'],
      });
    } else {
      await reFetchUserEvents(authUser.id);
      router.push(`${ERoutes.TICKET_CLAIMED}/${isWaitlist}`, 'forward');
    }
  };

  const getBtnText = () => {
    if (isClaiming.value) return <LoadingSpinner />;
    if (hasTicket.value) return 'You already have a ticket';
    if (isOnWaitlist.value) return 'You are on the waitlist';
    if (eventTickets.value?.remaining_tickets === 0) return 'Join waitlist';
    return 'Apply for ticket';
  };

  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <div className="content-section flex flex-col justify-start items-center">
          <Header title=" " />
          <img src={appEvent.value?.image_url} alt={appEvent.value?.title} className="w-full h-[175px] mt-6 object-cover rounded-card" />
          <h3 className="w-full truncate block mt-3">{appEvent.value?.title}</h3>

          <div className="flex flex-row justify-start gap-4 w-full">
            <div className="text-xs flex flex-row items-center gap-0.5 flex-1 truncate">
              <span className="w-[20px]">{getCalendarIcon()}</span> <span className="truncate">{appEvent.value?.date && mainDateFormat(new Date(appEvent.value.date))}</span>
            </div>
            <div className="text-xs flex flex-row items-center gap-0.5 flex-1 truncate justify-end mr-2">
              <span className="w-[20px]">{getMapPinIcon()}</span> <span className="truncate">{appEvent.value?.location}</span>
            </div>
          </div>

          <p className="text-sm overflow-hidden text-ellipsis line-clamp-6 my-3">{appEvent.value?.description ?? '...'}</p>

          <div className="mt-2 w-full bg-primary-tint rounded-xl h-[50px] flex flex-row justify-center items-center relative">
            <div className="absolute left-0 top-0 h-full bg-brand-gradient rounded-xl" style={{ width: `${pctBooked.value}%` }} />
            {
              <h3 className="text-base font-semibold z-10">
                {(eventTickets.value?.total_tickets ?? 0) - (eventTickets.value?.claimed_tickets ?? 0)}/{eventTickets.value?.total_tickets} tickets available
              </h3>
            }
          </div>

          <h3 className="mt-4 mb-2">Ticket deadline</h3>
          <div className="h-[70px] w-full bg-primary-tint/60 border-solid border-[1px] border-white/10 rounded-xl flex flex-row justify-between items-center">
            <div className="flex-1 h-full flex flex-col justify-center items-center">
              <h3 className="text-2xl leading-7">{countdown.value?.days}</h3>
              <p className="text-white/40 leading-4 tracking-wider text-xs">DAYS</p>
            </div>
            <div className="w-[1px] h-[70%] bg-gradient-to-b from-transparent via-white/20 to-transparent" />
            <div className="flex-1 h-full flex flex-col justify-center items-center">
              <h3 className="text-2xl leading-7">{countdown.value?.hours}</h3>
              <p className="text-white/40 leading-4 tracking-wider text-xs">HR</p>
            </div>
            <div className="w-[1px] h-[70%] bg-gradient-to-b from-transparent via-white/20 to-transparent" />
            <div className="flex-1 h-full flex flex-col justify-center items-center">
              <h3 className="text-2xl leading-7">{countdown.value?.minutes}</h3>
              <p className="text-white/40 leading-4 tracking-wider text-xs">MINS</p>
            </div>
            <div className="w-[1px] h-[70%] bg-gradient-to-b from-transparent via-white/20 to-transparent" />
            <div className="flex-1 h-full flex flex-col justify-center items-center">
              <h3 className="text-2xl leading-7">{countdown.value?.seconds}</h3>
              <p className="text-white/40 leading-4 tracking-wider text-xs">SECS</p>
            </div>
          </div>

          <h4 className="mt-4">Official sponsor</h4>
          <img src={sponsorImage.value} alt="sponsor logo" className="h-[50px] w-auto max-w-full mb-3 mt-1" />

          <button className="standard-btn w-full mt-4" disabled={isClaiming.value || hasTicket.value || isOnWaitlist.value} onClick={handleClaimTicket}>
            {getBtnText()}
          </button>
        </div>
      </IonContent>
    </IonPage>
  );
};
