import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Header } from 'ui/components/Header';

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <div className="content-section flex flex-col justify-start items-center h-full">
          <Header title="Privacy Policy" />
          <section className="p-2 mt-4">
            <p className="font-bold text-white text-lg my-4">1. Introduction</p>
            <p>
              This privacy policy explains how we collect, use, and protect your personal data. It applies to all products, services, and instances where we collect your personal
              data, whether online, via our app, or through other interactions.
            </p>
            <p>
              This policy is applicable to personal information processed by or on behalf of CROWDSURFR ApS, Denmark. For any questions regarding this policy or our data processing
              practices, please contact us at privacy@crowdsurfr.com.
            </p>

            <p className="font-bold text-white text-lg my-4">2. What Personal Information Do We Process?</p>
            <p>We may process the following types of personal data:</p>
            <p>- Personal and contact details: name, phone number, email, address, and contact history. </p>
            <p>- Demographic details: date of birth, gender, nationality (if required). </p>
            <p>- Digital information: IP address, MAC address, and mobile phone location data. </p>
            <p>- Product and service details: usage information, payment methods, and history of interest. </p>
            <p>- Marketing interactions: communication history, engagement with emails, and interests. </p>
            <p>- Third-party information: demographic data, marketing lists, and publicly available information. </p>
            <p>- Analytical insights: profiles created from data analysis.</p>

            <p className="font-bold text-white text-lg my-4">3. Sources of Personal Information</p>
            <p>We collect personal data from:</p>
            <p>- You directly, when you use our products or services.</p>
            <p>- Generated data when you interact with our services. </p>
            <p>- Brokers or intermediaries. </p>
            <p>- Business partners and service providers. </p>
            <p>- Public directories, social media, and other publicly available sources. </p>
            <p>- Purchased or rented data from third parties.</p>

            <p className="font-bold text-white text-lg my-4">4. Purpose of Processing Personal Data</p>
            <p>We use your data for:</p>
            <p>- Managing products and services.</p>
            <p>- Updating records and managing applications.</p>
            <p>- Automated decision-making processes.</p>
            <p>- Performance testing of products and services.</p>
            <p>- Business operations, including auditing.</p>
            <p>- Communication monitoring for quality control and training.</p>
            <p>- Market research and direct marketing.</p>
            <p>- Developing and improving products and services.</p>
            <p>- Legal and regulatory compliance.</p>
            <p>- Business insights and analytics.</p>
            <p>- Business partner collaborations and service provision.</p>

            <p className="font-bold text-white text-lg my-4">5. Legal Basis for Processing</p>
            <p>We rely on these legal bases:</p>
            <p>1. Contractual Necessity: Managing your accounts and services.</p>
            <p>2. Legitimate Interests: Business operations, performance testing, and marketing.</p>
            <p>3. Legal Obligations: Compliance with laws and regulations.</p>
            <p>4. Consent: Direct marketing and certain profiling activities.</p>

            <p className="font-bold text-white text-lg my-4">6. Data Sharing</p>
            <p>We may share your data with:</p>
            <p>- Affiliated companies and service providers.</p>
            <p>- Business partners.</p>
            <p>- Government and regulatory bodies.</p>
            <p>- Service providers for IT, storage, and back-office functions.</p>
            <p>- Market research organizations.</p>

            <p className="font-bold text-white text-lg my-4">7. Withdrawing Consent</p>
            <p>You can withdraw your consent at any time by contacting us at privacy@crowdsurfr.com.</p>

            <p className="font-bold text-white text-lg my-4">8. Data Transfers</p>
            <p>Your data may be transferred outside Denmark or the EU. We ensure appropriate safeguards are in place, such as approved contractual agreements.</p>

            <p className="font-bold text-white text-lg my-4">9. Updating Your Information</p>
            <p>Notify us of any changes to your personal data so we can update our records. Contact details are in the &quot;Contact Us&quot; section.</p>

            <p className="font-bold text-white text-lg my-4">10. Mandatory Information</p>
            <p>We cannot provide services without certain information. Optional information will be clearly indicated.</p>

            <p className="font-bold text-white text-lg my-4">11. Monitoring</p>
            <p>We may monitor communications as permitted by law for quality control, training, and security purposes.</p>

            <p className="font-bold text-white text-lg my-4">12. Automated Decision Making</p>
            <p>We use automated decision-making for service offerings and terms. These decisions are necessary for service performance or based on your consent.</p>

            <p className="font-bold text-white text-lg my-4">13. Data Retention</p>
            <p>We retain your data based on:</p>

            <p>- Business needs and relationship management.</p>
            <p>- Service provision duration and potential claims.</p>
            <p>- Legal and regulatory requirements.</p>

            <p className="font-bold text-white text-lg my-4">14. Your Data Protection Rights</p>
            <p>You have rights including:</p>

            <p>- Being informed about data processing.</p>
            <p>- Correcting inaccurate data.</p>
            <p>- Objecting to data processing.</p>
            <p>- Restricting data processing.</p>
            <p>- Erasing data (&quot;right to be forgotten&quot;).</p>
            <p>- Accessing your data.</p>
            <p>- Data portability.</p>
            <p>- Rights related to automated decision-making.</p>

            <p>Contact us or Datatilsynet (www.datatilsynet.dk) to exercise your rights.</p>

            <p className="font-bold text-white text-lg my-4">15. Right to Object</p>
            <p>You can object to data processing for direct marketing or legitimate interest purposes. Contact us at privacy@crowdsurfr.com.</p>

            <p className="font-bold text-white text-lg my-4">16. Marketing Preferences</p>
            <p>We may use your email and social media for marketing. Opt out through unsubscribe links or contact us.</p>

            <p className="font-bold text-white text-lg my-4">Contact Us</p>
            <p>For questions about this policy, contact us at privacy@crowdsurfr.com.</p>
          </section>
        </div>
      </IonContent>
    </IonPage>
  );
};
