import React, { FC, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { getCalendarIcon, getMapPinIcon, getOutlinedHeartIcon, getShareIcon } from 'static/assets/icons';
import { Share } from '@capacitor/share';

import styles from './activity-card.module.css';
import { mainDateFormat, wobbleAnimate, wobbleAnimateElement } from 'ui/helpers';
import { AppEvent, UserEvent } from 'apis/general';
import { useLikedEventsStore } from 'store/voting-state-store';

type FeedActivityCardProps = {
  className?: string;
  appEvent: AppEvent | UserEvent;
  onClick?: () => void;
};

export const FeedActivityCard: FC<FeedActivityCardProps> = ({ className = '', appEvent, onClick }) => {
  const { likeEvent, unlikeEvent, isEventLiked } = useLikedEventsStore();
  const cardRef = useRef<HTMLDivElement>(null);

  const handleLike = (event: any) => {
    event.stopPropagation();
    wobbleAnimate(event);
    if (isEventLiked(appEvent.id)) unlikeEvent(appEvent.id);
    else likeEvent(appEvent.id);
  };

  const handleShare = async (event: any) => {
    event.stopPropagation();
    wobbleAnimate(event);
    await Share.share({
      title: appEvent.title,
      text: mainDateFormat(new Date(appEvent.date)),
      url: appEvent.image_url,
      dialogTitle: 'Share with buddies',
    });
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    if (cardRef.current) wobbleAnimateElement(cardRef.current);
    onClick?.();
  };

  return (
    <div
      ref={cardRef}
      className={twMerge('relative w-full h-[175px] max-h-[175px] bg-no-repeat bg-cover border border-primary-tint rounded-xl button-wobble', className)}
      style={{ backgroundImage: `url('${appEvent.image_url}')` }}
      onClick={handleClick}
    >
      <div className={`${styles.underlay}`}></div>
      <div className="relative z-10 flex flex-col justify-between h-full p-2">
        <div className="flex flex-row justify-between items-center">
          <button onClick={handleLike} className="wobble-animate h-8 w-8 bg-black/40 rounded-full flex items-center justify-center">
            {getOutlinedHeartIcon('18px', isEventLiked(appEvent.id) ? 'currentColor' : 'none')}️
          </button>
          <button onClick={handleShare} className="wobble-animate h-8 w-8 bg-black/40 rounded-full flex items-center justify-center">
            {getShareIcon()}
          </button>
        </div>
        <div className="flex flex-col">
          <h4>{appEvent.title}</h4>

          <div className="flex flex-row justify-between w-full">
            <div className="text-xs flex flex-row items-center gap-0.5 flex-1">
              <span className="w-[20px]">{getMapPinIcon()}</span> <span className="truncate">{appEvent.location}</span>
            </div>
            <div className="text-xs flex flex-row items-center justify-end mr-2 gap-0.5 flex-1">
              <span className="w-[20px]">{getCalendarIcon()}</span> <span className="truncate">{mainDateFormat(new Date(appEvent.date))}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
