import React, { useEffect } from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import { Header } from 'ui/components/Header';
import { useAuthUserStore } from 'store/user-store';
import { getGiftIcon, getInfoIcon, getLogOutIcon, getPenIcon, getPrivacyTriangle, getTicketIcon, getTrashIcon, getTrustBadge } from 'static/assets/icons';
import { profileRewards, reFetchProfileRewards } from 'apis/general';
import { ERoutes } from 'static/data/ERoutes';
import { wobbleAnimate } from 'ui/helpers';
import avatar from 'static/assets/img/avatar.png';

const menuItemClass = 'text-base text-secondary-color py-4 border-b-2 border-solid border-white/10 flex flex-row items-center gap-2.5 disabled:opacity-50';
export const ProfilePage: React.FC = () => {
  const router = useIonRouter();
  const { authUser } = useAuthUserStore();

  useEffect(() => {
    if (authUser?.id) void reFetchProfileRewards(authUser.id);
  }, []);

  const handleMenuClick = (event: any, destination: ERoutes) => {
    event.stopPropagation();
    wobbleAnimate(event);
    router.push(destination, 'forward');
  };

  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <div className="content-section flex flex-col justify-start items-center h-full">
          <Header title="Profile" />

          <div className="w-full rounded-md mb-4 mt-14 flex flex-col items-center">
            <div className="bg-no-repeat bg-cover h-28 w-28 rounded-full relative" style={{ backgroundImage: `url('${avatar}')` }}>
              <div className="bg-neutral-800 border-2 border-solid border-neutral-600 h-9 w-9 rounded-full absolute right-0 bottom-0 flex flex-col justify-center items-center">
                {getPenIcon()}
              </div>
            </div>

            <h2 className="mb-0 mt-2">Anonymous</h2>
            <p className="text-lg">{profileRewards.value?.points ?? '..'} points</p>
          </div>

          <div className="w-full flex flex-col">
            <button disabled className={`${menuItemClass} border-white/10`} onClick={(e) => handleMenuClick(e, ERoutes.PROFILE)}>
              {getGiftIcon()}Point shop
            </button>
            <button className={`${menuItemClass} border-white/10`} onClick={(e) => handleMenuClick(e, ERoutes.MY_EVENTS)}>
              {getTicketIcon()}My events
            </button>
            <button className={`${menuItemClass} border-white/10`} onClick={(e) => handleMenuClick(e, ERoutes.TRUST_AND_SAFETY)}>
              {getTrustBadge()}Trust and safety
            </button>
            <button className={`${menuItemClass} border-white/10`} onClick={(e) => handleMenuClick(e, ERoutes.TERMS_OF_USE)}>
              {getInfoIcon()}Terms of use
            </button>
            <button className={`${menuItemClass} border-white/10`} onClick={(e) => handleMenuClick(e, ERoutes.PRIVACY_POLICY)}>
              {getPrivacyTriangle()}Privacy policy
            </button>
            <button className={`${menuItemClass} border-white/10`} onClick={(e) => handleMenuClick(e, ERoutes.LANDING)}>
              {getLogOutIcon()}Log out
            </button>
            <button className={`${menuItemClass} border-transparent`} onClick={(e) => handleMenuClick(e, ERoutes.PROFILE)}>
              {getTrashIcon()}Delete account
            </button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
