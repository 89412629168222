import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Header } from 'ui/components/Header';

export const TrustSafetyPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <div className="content-section flex flex-col justify-start items-center h-full">
          <Header title="Trust & Safety" />

          <section className="p-2 mt-4">
            <p className="font-bold text-white text-lg my-4">Welcome to Crowdsurfr</p>
            <p>
              At Crowdsurfr trust and safety are our top priorities. We are committed to providing a secure platform that our users can rely on. Our team continuously investigates
              and addresses any issues that may arise within our community. Our ultimate goal is to create a safe, transparent, and enjoyable platform where unforgettable memories
              are made, and the boundaries of entertainment are pushed.
            </p>
            <p className="font-bold text-white text-lg my-4">What You Should Know</p>
            <p>
              Crowdsurfr is not just a typical ticket outlet or venue. Our platform allows users to support the creation of events and concerts. While creating events or concerts
              can be challenging, we encourage you to approach it with patience and curiosity—eventually, everything will fall into place.
            </p>
            <p className="font-bold text-white text-lg my-4">For Supporters</p>
            <ol className="flex flex-col gap-2">
              <li>
                <p>
                  <span className="font-bold text-white my-4">1. Event Research:</span> Before supporting an event, take the time to research the creator. Artists, agents, bookers,
                  management, venues, businesses, and institutions (collectively referred to as creators) are responsible for their projects. Crowdsurfr does not evaluate all
                  events, so it’s up to you to decide what to support.
                </p>
              </li>
              <li>
                <p>
                  <span className="font-bold text-white my-4">2. Event Details:</span> Every event page contains detailed information to help you make informed decisions. Verified
                  accounts add an extra layer of security. Read comments, check the costs, and explore all details thoroughly.
                </p>
              </li>
              <li>
                <p>
                  <span className="font-bold text-white my-4">3. Ask Questions:</span> If you have any questions about an event, contact the creator directly. If the creator cannot
                  help, our team is here to assist. Visit our &quot;About Us&quot; page and send us a message. Report any suspicious activity to help us keep the platform safe.
                </p>
              </li>
            </ol>
            <p className="font-bold text-white text-lg my-4">For Creators</p>
            <ol className="flex flex-col gap-2">
              <li>
                <p>
                  <span className="font-bold text-white my-4">1. Transparency and Responsiveness:</span> Be open, honest, and responsive. Answer all questions from supporters
                  promptly and thoroughly. This helps build trust and can aid in getting your account verified.
                </p>
              </li>
              <li>
                <p>
                  <span className="font-bold text-white my-4">2. Honesty and Ethics:</span> Avoid making unrealistic promises. Present your event or concert honestly to build a
                  good reputation on Crowdsurfr and among users and partners.
                </p>
              </li>
              <li>
                <p>
                  <span className="font-bold text-white my-4">3. Trusted Partnerships:</span> We verify and add new creators continuously. If approached by third parties for
                  promotion or marketing, remember that Crowdsurfr does not endorse these services. Take necessary precautions and remember that you are responsible for your
                  actions on the platform.
                </p>
              </li>
            </ol>
            <p className="font-bold text-white text-lg my-4">Our Commitment</p>
            <ol className="flex flex-col gap-2">
              <li>
                <p>
                  <span className="font-bold text-white my-4">1. Continuous Improvement:</span> Your feedback is invaluable to us. It helps us build a better platform and enhances
                  your experience.
                </p>
              </li>
              <li>
                <p>
                  <span className="font-bold text-white my-4">2. System Monitoring:</span> We constantly monitor our system to ensure it runs smoothly and securely.
                </p>
              </li>
            </ol>
            <p className="mt-4">
              Crowdsurfr is a powerful tool for bringing live events and concerts to life. Thousands of users from various sectors have tested our platform, supporting local
              artists and communities. We believe in honesty and transparency and hope that both supporters and creators embrace these values.
            </p>
            <p className="mt-4"> Thank you for being a part of our innovative journey.</p>
            <p className="mt-4 font-bold text-white my-4">Crowdsurfr Team</p>
          </section>
        </div>
      </IonContent>
    </IonPage>
  );
};
