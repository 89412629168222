import { ArrayElement, supabase } from './supabaseClient';
import { signal } from '@preact/signals-react';
import { Tables } from '../types/database.types';

export const eventCategories = signal<Tables<'category'>[]>([]);
export const appEvents = signal<AppEvents>([]);
export const appEventView = signal<Tables<'event_view'>[] | null>(null);
export const userEvents = signal<UserEvents>([]);
export const userWaitlists = signal<Tables<'waitlist'>[]>([]);
export const profileRewards = signal<Tables<'profile_rewards'> | null>(null);

/** Fetches all static data and puts it in the relevant global signals. */
export const fetchStaticData = async () => {
  supabase
    .from('category')
    .select('*')
    .order('order', { ascending: true })
    .then(({ data, error }) => {
      if (error) console.error('Error fetching categories:', error);
      else eventCategories.value = data;
    });

  fetchEvents().then((data) => (appEvents.value = data));
};

export type UserProfile = Awaited<ReturnType<typeof fetchUserProfile>>;
export const fetchUserProfile = async (authId: string) => {
  const { data, error } = await supabase.from('profile').select('*, profile_rewards(*)').eq('id', authId).single();

  if (error) {
    console.error('Error fetching user profile:', error);
    return null;
  }

  return data;
};

export type AppEvents = Awaited<ReturnType<typeof fetchEvents>>;
export type AppEvent = ArrayElement<AppEvents>;
export const fetchEvents = async () => {
  const { data, error } = await supabase.from('event').select('*, category(*)').order('date', { ascending: true });

  if (error) {
    console.error('Error fetching events:', error);
    return [];
  }

  return data;
};

export type UserEvents = Awaited<ReturnType<typeof reFetchUserEvents>>;
export type UserEvent = ArrayElement<UserEvents>;
export const reFetchUserEvents = async (authId: string) => {
  const { data, error } = await supabase.from('ticket_holder').select('*, event(*)').eq('profile_id', authId);

  if (error) {
    console.error('Error fetching user events:', error);
    userEvents.value = [];
    return [];
  }

  const validEvents = data.map((holder) => holder.event).filter((event) => !!event);
  userEvents.value = validEvents;
  console.log('User id: ', authId, ' Data: ', validEvents);

  return validEvents;
};

export const reFetchUserWaitlists = async (authId: string) => {
  const { data, error } = await supabase.from('waitlist').select('*, event(*)').eq('profile_id', authId);

  if (error) {
    console.error('Error fetching user waitlists:', error);
    userWaitlists.value = [];
    return [];
  }

  userWaitlists.value = data;
  return data;
};

export const reFetchProfileRewards = async (authId: string) => {
  const { data, error } = await supabase.from('profile_rewards').select('*').eq('profile_id', authId).single();

  if (error) {
    console.error('Error fetching profile rewards:', error);
    profileRewards.value = null;
    return null;
  }

  profileRewards.value = data;
  return data;
};

export const reFetchEventView = async () => {
  const { data, error } = await supabase.from('event_view').select('*');
  if (error) {
    console.error('Error fetching event view:', error);
    appEventView.value = null;
    return null;
  }

  appEventView.value = data;
  return data;
};

export const claimTicket = async (eventId: string, profileId: string): Promise<boolean> => {
  const { error } = await supabase.from('ticket_holder').insert({ event_id: eventId, profile_id: profileId });

  if (error) {
    console.error('Error claiming ticket:', error);
    return false;
  }

  return true;
};

export const joinWaitlist = async (eventId: string, profileId: string) => {
  const { error } = await supabase.from('waitlist').insert({ event_id: eventId, profile_id: profileId });

  if (error) {
    console.error('Error joining waitlist:', error);
    return false;
  }

  return true;
};

export const unclaimTicket = async (eventId: string, profileId: string): Promise<boolean> => {
  const { error } = await supabase.from('ticket_holder').delete().eq('event_id', eventId).eq('profile_id', profileId);

  if (error) {
    console.error('Error unclaiming ticket:', error);
    return false;
  }

  await reFetchUserEvents(profileId);

  return true;
};
