import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ERoutes } from './static/data/ERoutes';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

// Import Swiper styles
import 'swiper/css';

/* Tailwind & Ant-d CSS imports */
import './ui/theme/tailwind-setup.css';

/* Theme variables */
import './ui/theme/variables.css';
import './ui/theme/ionic-variables.css';
import './ui/theme/global.css';
import './mobile-wrapper.css';

/* _i18n */
import './_i18n/config';

import { Redirect } from 'react-router';

/** Pages imports */
import { PhoneAccessPage } from './ui/pages/PhoneAccessPage';
import { WelcomePage } from './ui/pages/WelcomePage';
import { HomePage } from './ui/pages/HomePage';
import { SplashPage } from './ui/pages/SplashPage';
import { fetchStaticData } from './apis/general';
import { MyEventsPage } from './ui/pages/MyEventsPage';
import { ShowTicketPage } from './ui/pages/ShowTicketPage';
import { ProfilePage } from './ui/pages/ProfilePage';
import { EventDetailsPage } from './ui/pages/EventDetailsPage';
import { TicketClaimedPage } from './ui/pages/TicketClaimedPage';
import { TrustSafetyPage } from './ui/pages/TrustSafetyPage';
import { TermsOfUsePage } from './ui/pages/TermsOfUsePage';
import { PrivacyPolicyPage } from './ui/pages/PrivacyPolicyPage';

setupIonicReact({ mode: 'ios' });

export const isDebugging = false;
export const appVersion = '0.1';

const App: React.FC = () => {
  console.log('App rendered.');
  void fetchStaticData();

  useEffect(() => {
    // Event delegation: Add a single event listener to the document
    const handleClick = (event: any) => {
      const el = event.target;

      if (isDebugging) console.log('el', el);

      // Check if the clicked element is a button with the class 'wobble-animate'
      if (
        el.classList.contains('standard-btn') ||
        el.classList.contains('wobble-animate') ||
        el.classList.contains('standard-btn-secondary') ||
        el.classList.contains('standard-input')
      ) {
        if (!el.classList.contains('no-wobble')) el.classList.add('button-wobble');
      }
    };

    // Remove 'button-wobble' class after the animation ends
    const handleAnimationEnd = (event: any) => {
      event.target.classList.remove('button-wobble');
    };

    // Add event listeners
    document.addEventListener('click', handleClick);
    document.addEventListener('animationend', handleAnimationEnd);

    // Cleanup function to remove event listeners on unmount
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('animationend', handleAnimationEnd);
    };
  }, []);

  /** This effect cleans up the mis-layout on first load in new browsers. */
  useEffect(() => {
    if ('ontouchstart' in window) {
      // On first touch, lock the viewport size
      document.body.style.height = `${window.innerHeight}px`;
      document.body.style.overflow = 'hidden';
    }

    return () => {
      // Clean up: revert any changes when the component is unmounted
      document.body.style.height = '';
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className="mobile-wrapper">
      <h3 className="invisible sm:visible absolute italic top-[10px] xl:top-[20px] z-10 left-0 h-[50px] w-full text-center text-white/70">This app is meant for mobile.</h3>
      <div className="mobile-container">
        <IonApp>
          <IonReactRouter>
            <IonRouterOutlet animated={true} id="main-content">
              <Route exact path={ERoutes.BASE} component={SplashPage} />
              <Route exact path={ERoutes.PHONE_ACCESS} component={PhoneAccessPage} />
              <Route exact path={ERoutes.WELCOME} component={WelcomePage} />

              <Route exact path={ERoutes.HOME} component={HomePage} />

              <Route exact path={ERoutes.MY_EVENTS} component={MyEventsPage} />
              <Route exact path={ERoutes.PROFILE} component={ProfilePage} />
              <Route exact path={`${ERoutes.SHOW_TICKET}/:event_id`} component={ShowTicketPage} />
              <Route exact path={`${ERoutes.EVENT_DETAILS}/:event_id`} component={EventDetailsPage} />
              <Route exact path={`${ERoutes.TICKET_CLAIMED}/:is_waitlist`} component={TicketClaimedPage} />

              <Route exact path={ERoutes.TRUST_AND_SAFETY} component={TrustSafetyPage} />
              <Route exact path={ERoutes.TERMS_OF_USE} component={TermsOfUsePage} />
              <Route exact path={ERoutes.PRIVACY_POLICY} component={PrivacyPolicyPage} />

              <Route render={() => <Redirect to={ERoutes.BASE} />} />
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      </div>
    </div>
  );
};
export default App;
